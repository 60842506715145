import { defineMessages } from 'react-intl-next';

export const utilMessages = defineMessages({
	hyperlinkIconIssueLabel: {
		id: 'fabric.editor.headingLink.hyperlinkIconIssueLabel',
		defaultMessage: 'Issue',
		description: 'icon label for a jira issue',
	},
	hyperlinkIconBugLabel: {
		id: 'fabric.editor.headingLink.hyperlinkIconBugLabel',
		defaultMessage: 'Bug',
		description: 'icon label for a jira bug',
	},
	hyperlinkIconStoryLabel: {
		id: 'fabric.editor.headingLink.hyperlinkIconStoryLabel',
		defaultMessage: 'Story',
		description: 'icon label for a jira story',
	},
	hyperlinkIconTaskLabel: {
		id: 'fabric.editor.headingLink.hyperlinkIconTaskLabel',
		defaultMessage: 'Task',
		description: 'icon label for a jira task',
	},
	hyperlinkIconPageLabel: {
		id: 'fabric.editor.headingLink.hyperlinkIconPageLabel',
		defaultMessage: 'Page',
		description: 'icon label for a confluence page',
	},
	hyperlinkIconBlogLabel: {
		id: 'fabric.editor.headingLink.hyperlinkIconBlogLabel',
		defaultMessage: 'Blog',
		description: 'icon label for a confluence blog',
	},
	hyperlinkIconIssueLabelIssueTermRefresh: {
		id: 'fabric.editor.headingLink.hyperlinkIconIssueLabel.issue-term-refresh',
		defaultMessage: 'Work Item',
		description: 'icon label for a jira issue',
	},
});
