/* eslint-disable @atlaskit/ui-styling-standard/enforce-style-prop */
import React from 'react';

import { token } from '@atlaskit/tokens';

const SVGContainer = ({ children }: React.PropsWithChildren<object>) => (
	<span
		style={{
			width: token('space.300', '24px'),
			height: token('space.300', '24px'),
			display: 'flex',
			justifyContent: 'flex-start',
			alignItems: 'center',
		}}
	>
		{children}
	</span>
);

export const Text = () => (
	<SVGContainer>
		<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M2 1H14V5H12.5V2.5H8.8125V13.5H10.5V15H5.5V13.5H7.3125V2.5H3.5V5H2V1Z"
				fill="currentColor"
			/>
		</svg>
	</SVGContainer>
);

export const H1 = () => (
	<SVGContainer>
		<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M0 0H1.5V6.25H6V0H7.5V6.25V7.75V14H6V7.75H1.5V14H0V7.75V6.25V0ZM9.85801 12.6959V14H13.9999V12.6959H12.8161V7.83108H11.3138L9.8418 8.86406V10.4342L11.3499 9.31528V12.6959H9.85801Z"
				fill="currentColor"
			/>
		</svg>
	</SVGContainer>
);

export const H2 = () => (
	<SVGContainer>
		<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				clip-rule="evenodd"
				d="M1.5 0H0V6.25V7.75V14H1.5V7.75H6V14H7.5V7.75V6.25V0H6V6.25H1.5V0ZM9.2395 12.8296V14H14V12.6553H11.5637V12.573L12.4146 11.875C12.7826 11.5734 13.0772 11.2981 13.2986 11.0491C13.5199 10.8 13.679 10.5635 13.7759 10.3394C13.8755 10.1152 13.9253 9.89111 13.9253 9.66699V9.64624C13.9253 9.28101 13.8285 8.96419 13.6348 8.6958C13.4411 8.42464 13.1685 8.21574 12.8171 8.06909C12.4685 7.91968 12.0562 7.84497 11.5803 7.84497C11.0906 7.84497 10.6617 7.93075 10.2937 8.10229C9.92847 8.27108 9.64347 8.50903 9.43872 8.81616C9.23674 9.12052 9.13574 9.47884 9.13574 9.89111V9.92432H10.7378V9.88696C10.7378 9.74308 10.771 9.6158 10.8374 9.50513C10.9038 9.39445 10.9951 9.30729 11.1113 9.24365C11.2303 9.18001 11.3659 9.14819 11.5181 9.14819C11.6647 9.14819 11.7906 9.1731 11.8958 9.2229C12.0037 9.27271 12.0853 9.34465 12.1406 9.43872C12.1987 9.5328 12.2278 9.64486 12.2278 9.7749V9.79565C12.2278 9.9008 12.1946 10.0115 12.1282 10.1277C12.0645 10.2439 11.9497 10.3878 11.7837 10.5593C11.6177 10.7281 11.3811 10.9467 11.074 11.2151L9.2395 12.8296Z"
				fill="currentColor"
			/>
		</svg>
	</SVGContainer>
);

export const H3 = () => (
	<SVGContainer>
		<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M1.5 0H0V6.25V7.75V14H1.5V7.75H6V14H7.5V7.75V6.25V0H6V6.25H1.5V0ZM10.219 13.7649C10.5811 13.9216 11.0012 14 11.4794 14C12.0009 14 12.4494 13.9257 12.825 13.7771C13.2005 13.6258 13.4896 13.411 13.6922 13.1327C13.8976 12.8544 14.0002 12.5248 14.0002 12.1438V12.1357C14.0002 11.7575 13.8706 11.4535 13.6112 11.2239C13.3518 10.9942 13.0006 10.8564 12.5575 10.8105V10.7781C12.7709 10.7348 12.9681 10.6565 13.1492 10.543C13.3329 10.4295 13.4801 10.2823 13.5909 10.1013C13.7017 9.91755 13.7571 9.70275 13.7571 9.45688V9.44878C13.7571 9.12186 13.6639 8.83817 13.4774 8.5977C13.291 8.35454 13.0262 8.16541 12.6831 8.03032C12.34 7.89523 11.9347 7.82769 11.4673 7.82769C11.0134 7.82769 10.6162 7.90469 10.2758 8.05869C9.93805 8.20999 9.67057 8.42479 9.47334 8.70308C9.27611 8.98136 9.16533 9.31099 9.14102 9.69194L9.13696 9.75273H10.6851L10.6892 9.71626C10.7027 9.58927 10.7418 9.4785 10.8067 9.38394C10.8715 9.28667 10.9593 9.21237 11.0701 9.16104C11.1836 9.107 11.316 9.07998 11.4673 9.07998C11.6132 9.07998 11.7388 9.10565 11.8442 9.15698C11.9523 9.20562 12.0347 9.27586 12.0914 9.36772C12.1481 9.45959 12.1765 9.56631 12.1765 9.68789V9.696C12.1765 9.81488 12.1427 9.9189 12.0752 10.0081C12.0104 10.0945 11.9158 10.1607 11.7915 10.2066C11.6672 10.2526 11.5159 10.2755 11.3376 10.2755H10.7337V11.4062H11.3376C11.6348 11.4062 11.8618 11.4589 12.0185 11.5643C12.1752 11.667 12.2535 11.8183 12.2535 12.0182V12.0263C12.2535 12.1506 12.2224 12.2614 12.1603 12.3586C12.0982 12.4532 12.009 12.5289 11.8928 12.5856C11.7766 12.6396 11.6375 12.6667 11.4754 12.6667C11.3133 12.6667 11.1728 12.641 11.0539 12.5896C10.935 12.5383 10.8405 12.4681 10.7702 12.3789C10.7 12.2897 10.6567 12.1911 10.6405 12.0831L10.6324 12.0344H8.99512L8.99917 12.0871C9.02889 12.4762 9.14912 12.8139 9.35986 13.1003C9.57061 13.3867 9.857 13.6082 10.219 13.7649Z"
				fill="currentColor"
			/>
		</svg>
	</SVGContainer>
);

export const H4 = () => (
	<SVGContainer>
		<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M1.5 0H0V6.25V7.75V14H1.5V7.75H6V14H7.5V7.75V6.25V0H6V6.25H1.5V0ZM11.542 13.0117V14H13.2607V13.0117H13.9998V11.6195H13.2607V7.79961H10.7428C10.5852 8.05169 10.4291 8.30378 10.2744 8.55586C10.1226 8.80508 9.97077 9.05573 9.81894 9.30781C9.66999 9.5599 9.52103 9.81628 9.37207 10.077C9.22311 10.3348 9.07559 10.5969 8.92949 10.8633C8.7834 11.1268 8.6373 11.3975 8.49121 11.6754V13.0117H11.542ZM11.5936 11.6883H10.0338V11.6023C10.1226 11.4276 10.2143 11.2543 10.3088 11.0824C10.4033 10.9077 10.4993 10.7344 10.5967 10.5625C10.6969 10.3906 10.7972 10.2202 10.8975 10.0512C10.9977 9.8793 11.098 9.71029 11.1982 9.54414C11.3014 9.37799 11.4045 9.21328 11.5076 9.05H11.5936V11.6883Z"
				fill="currentColor"
			/>
		</svg>
	</SVGContainer>
);

export const H5 = () => (
	<SVGContainer>
		<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M1.5 0H0V6.25V7.75V14H1.5V7.75H6V14H7.5V7.75V6.25V0H6V6.25H1.5V0ZM10.2808 13.7551C10.6349 13.9184 11.0527 14 11.5342 14C12.0239 14 12.4542 13.9101 12.825 13.7302C13.1957 13.5476 13.4835 13.2917 13.6882 12.9624C13.8958 12.6331 13.9995 12.2499 13.9995 11.8127V11.8044C13.9995 11.4281 13.9165 11.0961 13.7505 10.8083C13.5872 10.5178 13.3617 10.2896 13.074 10.1235C12.7862 9.95752 12.457 9.87451 12.0862 9.87451C11.887 9.87451 11.703 9.90356 11.5342 9.96167C11.3682 10.0198 11.2243 10.1 11.1025 10.2024C10.9808 10.3048 10.884 10.4237 10.812 10.5593H10.729L10.8203 9.1731H13.6177V7.84497H9.45898L9.22241 11.4973H10.7207C10.7511 11.4392 10.7913 11.3839 10.8411 11.3313C10.8909 11.276 10.9462 11.2275 11.0071 11.186C11.079 11.139 11.1579 11.103 11.2437 11.0781C11.3322 11.0505 11.4277 11.0366 11.53 11.0366C11.6933 11.0366 11.8385 11.0712 11.9658 11.1404C12.0931 11.2068 12.1927 11.2995 12.2646 11.4185C12.3366 11.5374 12.3726 11.6772 12.3726 11.8376V11.8459C12.3726 12.0037 12.3366 12.1448 12.2646 12.2693C12.1927 12.391 12.0931 12.4865 11.9658 12.5557C11.8413 12.6248 11.6974 12.6594 11.5342 12.6594C11.3903 12.6594 11.2616 12.6345 11.1482 12.5847C11.0347 12.5349 10.9393 12.4685 10.8618 12.3855C10.7871 12.2997 10.7318 12.2056 10.6958 12.1033L10.6875 12.0784H9.12695L9.1311 12.1531C9.15047 12.4989 9.25838 12.8116 9.45483 13.0911C9.65405 13.3705 9.92936 13.5919 10.2808 13.7551Z"
				fill="currentColor"
			/>
		</svg>
	</SVGContainer>
);

export const H6 = () => (
	<SVGContainer>
		<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M1.5 0H0V6.25V7.75V14H1.5V7.75H6V14H7.5V7.75V6.25V0H6V6.25H1.5V0ZM10.4615 13.7893C10.7857 13.9298 11.1531 14 11.5638 14C12.0501 14 12.4757 13.9068 12.8404 13.7204C13.2052 13.5339 13.4889 13.28 13.6915 12.9584C13.8968 12.6342 13.9995 12.2681 13.9995 11.8602V11.8521C13.9995 11.4873 13.9225 11.1644 13.7685 10.8834C13.6172 10.5998 13.3997 10.3782 13.116 10.2188C12.835 10.0567 12.4973 9.97563 12.1028 9.97563C11.8272 9.97563 11.5935 10.0135 11.4017 10.0891C11.2099 10.1648 11.0532 10.2634 10.9316 10.385C10.81 10.5065 10.7141 10.6389 10.6438 10.7821H10.5547C10.5548 10.7402 10.5561 10.6984 10.5587 10.6565C10.575 10.3539 10.6195 10.0878 10.6925 9.8581C10.7681 9.62845 10.8762 9.44878 11.0167 9.31909C11.1572 9.1894 11.3328 9.12456 11.5436 9.12456C11.6624 9.12456 11.7678 9.14347 11.8597 9.1813C11.9542 9.21912 12.0353 9.27046 12.1028 9.3353C12.1704 9.40015 12.2204 9.47309 12.2528 9.55415L12.2649 9.58252H13.9266L13.9225 9.5582C13.8685 9.23398 13.7334 8.94219 13.5172 8.68281C13.3011 8.42074 13.0215 8.21269 12.6783 8.05869C12.3379 7.90469 11.9542 7.82768 11.5273 7.82768C11.0032 7.82768 10.5466 7.95062 10.1575 8.19648C9.76846 8.44235 9.4672 8.79629 9.25376 9.2583C9.04032 9.71761 8.93359 10.2674 8.93359 10.9078V10.9159C8.93359 11.3941 8.99303 11.8237 9.11191 12.2046C9.23079 12.5856 9.40371 12.9098 9.63066 13.1773C9.86032 13.4421 10.1373 13.6461 10.4615 13.7893ZM10.7492 11.9047C10.7492 12.056 10.7843 12.1925 10.8546 12.3141C10.9275 12.4356 11.0248 12.5316 11.1464 12.6018C11.2707 12.6694 11.4071 12.7031 11.5557 12.7031C11.707 12.7031 11.8435 12.6694 11.965 12.6018C12.0866 12.5316 12.1839 12.4383 12.2568 12.3222C12.3298 12.206 12.3663 12.075 12.3663 11.9291V11.9209C12.3663 11.7696 12.3311 11.6359 12.2609 11.5197C12.1906 11.4035 12.0947 11.3117 11.9731 11.2441C11.8543 11.1766 11.7165 11.1428 11.5598 11.1428C11.4112 11.1428 11.2747 11.1752 11.1504 11.2401C11.0289 11.3022 10.9316 11.39 10.8586 11.5035C10.7857 11.617 10.7492 11.748 10.7492 11.8966V11.9047Z"
				fill="currentColor"
			/>
		</svg>
	</SVGContainer>
);
