export enum ACTIONS {
	OPEN_TYPEAHEAD_AT_CURSOR = 'OPEN_TYPEAHEAD_AT_CURSOR',
	CLOSE_TYPE_AHEAD = 'CLOSE_TYPE_AHEAD',
	CHANGE_QUERY = 'CHANGE_QUERY',
	INSERT_ITEM = 'INSERT_ITEM',
	INSERT_RAW_QUERY = 'INSERT_RAW_QUERY',
	UPDATE_LIST_ITEMS = 'UPDATE_LIST_ITEMS',
	UPDATE_LIST_ERROR = 'UPDATE_LIST_ERROR',
	UPDATE_SELECTED_INDEX = 'UPDATE_SELECTED_INDEX',
}
