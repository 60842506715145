import { defineMessages } from 'react-intl-next';

export const messages = defineMessages({
	normal: {
		id: 'fabric.editor.normal',
		defaultMessage: 'Normal text',
		description: 'This is the default text style',
	},
	heading1: {
		id: 'fabric.editor.heading1',
		defaultMessage: 'Heading 1',
		description:
			'Used for the title of a section of your document, headings run from 1 (largest size) to 6 (smallest size)',
	},
	heading1Description: {
		id: 'fabric.editor.heading1Description',
		defaultMessage: 'Use this for a top level heading',
		description: 'Description of the main heading, heading 1',
	},
	heading2: {
		id: 'fabric.editor.heading2',
		defaultMessage: 'Heading 2',
		description:
			'Used for the title of a section of your document, headings run from 1 (largest size) to 6 (smallest size)',
	},
	heading2Description: {
		id: 'fabric.editor.heading2Description',
		defaultMessage: 'Use this for key sections',
		description: 'Description of a subtitle heading or secondary heading',
	},
	heading3: {
		id: 'fabric.editor.heading3',
		defaultMessage: 'Heading 3',
		description:
			'Used for the title of a section of your document, headings run from 1 (largest size) to 6 (smallest size)',
	},
	heading3Description: {
		id: 'fabric.editor.heading3Description',
		defaultMessage: 'Use this for sub sections and group headings',
		description: '',
	},
	heading4: {
		id: 'fabric.editor.heading4',
		defaultMessage: 'Heading 4',
		description:
			'Used for the title of a section of your document, headings run from 1 (largest size) to 6 (smallest size)',
	},
	heading4Description: {
		id: 'fabric.editor.heading4Description',
		defaultMessage: 'Use this for deep headings',
		description: '',
	},
	heading5: {
		id: 'fabric.editor.heading5',
		defaultMessage: 'Heading 5',
		description:
			'Used for the title of a section of your document, headings run from 1 (largest size) to 6 (smallest size)',
	},
	heading5Description: {
		id: 'fabric.editor.heading5Description',
		defaultMessage: 'Use this for grouping list items',
		description: '',
	},
	heading6: {
		id: 'fabric.editor.heading6',
		defaultMessage: 'Heading 6',
		description:
			'Used for the title of a section of your document, headings run from 1 (largest size) to 6 (smallest size)',
	},
	heading6Description: {
		id: 'fabric.editor.heading6Description',
		defaultMessage: 'Use this for low level headings',
		description: '',
	},
	blockquote: {
		id: 'fabric.editor.blockquote2',
		defaultMessage: 'Quote',
		description: 'Quote some text',
	},
	blockquoteDescription: {
		id: 'fabric.editor.blockquote.description',
		defaultMessage: 'Insert a quote or citation',
		description: 'Quote some text',
	},
	codeblock: {
		id: 'fabric.editor.codeblock',
		defaultMessage: 'Code snippet',
		description: 'Insert a snippet/segment of code (code block)',
	},
	codeblockDescription: {
		id: 'fabric.editor.codeblock.description',
		defaultMessage: 'Display code with syntax highlighting',
		description: 'Insert a snippet/segment of code (code block)',
	},
	infoPanel: {
		id: 'fabric.editor.infoPanel',
		defaultMessage: 'Info panel',
		description:
			'Visually distinguishes your text by adding a background colour (blue, purple, yellow, green, red)',
	},
	infoPanelDescription: {
		id: 'fabric.editor.infoPanel.description',
		defaultMessage: 'Highlight information in a colored panel',
		description:
			'Visually distinguishes your text by adding a background colour (blue, purple, yellow, green, red)',
	},
	notePanel: {
		id: 'fabric.editor.notePanel',
		defaultMessage: 'Note panel',
		description: 'Visually distinguishes your text by adding a note panel',
	},
	notePanelDescription: {
		id: 'fabric.editor.notePanel.description',
		defaultMessage: 'Add a note in a colored panel',
		description: 'Visually distinguishes your text by adding a note panel',
	},
	successPanel: {
		id: 'fabric.editor.successPanel',
		defaultMessage: 'Success panel',
		description: 'Visually distinguishes your text by adding a success panel',
	},
	successPanelDescription: {
		id: 'fabric.editor.successPanel.description',
		defaultMessage: 'Add tips in a colored panel',
		description: 'Visually distinguishes your text by adding a success panel',
	},
	warningPanel: {
		id: 'fabric.editor.warningPanel',
		defaultMessage: 'Warning panel',
		description: 'Visually distinguishes your text by adding a warning panel',
	},
	warningPanelDescription: {
		id: 'fabric.editor.warningPanel.description',
		defaultMessage: 'Add a note of caution in a colored panel',
		description: 'Visually distinguishes your text by adding a warning panel',
	},
	errorPanel: {
		id: 'fabric.editor.errorPanel',
		defaultMessage: 'Error panel',
		description: 'Visually distinguishes your text by adding a error panel',
	},
	errorPanelDescription: {
		id: 'fabric.editor.errorPanel.description',
		defaultMessage: 'Call out errors in a colored panel',
		description: 'Visually distinguishes your text by adding a error panel',
	},
	customPanel: {
		id: 'fabric.editor.customPanel',
		defaultMessage: 'Custom panel',
		description: 'Visually distinguishes your panel by adding a emoji icon and background color',
	},
	customPanelDescription: {
		id: 'fabric.editor.customPanel.description',
		defaultMessage: 'Add a note with an emoji and colored background',
		description: 'Visually distinguishes your panel by adding a emoji icon and background color ',
	},
	panel: {
		id: 'fabric.editor.panel',
		defaultMessage: 'Panel',
		description: 'Visually distinguishes your text by adding a panel',
	},
	other: {
		id: 'fabric.editor.other',
		defaultMessage: 'Others...',
		description: 'Other text formatting',
	},
});
